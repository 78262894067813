@import "./_configs/index.scss";

$border-color: #07aa0d;
$triangle-margins: calc($btn-size * 0.07);
$triangle-border-widths: 7px;

.fullscreen-btn {
   @extend .tile-btns;

   transform: rotate(135deg);

   &.enable {
      .triangle {
         width: 0;
         height: 0;
         border-style: solid;

         &.triangle-1 {
            border-top-width: $triangle-border-widths;
            border-right-width: $triangle-border-widths;
            border-bottom-width: $triangle-border-widths;
            border-left-width: 0;
            border-color: transparent #ffffff transparent transparent;
            margin-right: $triangle-margins;
         }

         &.triangle-2 {
            border-top-width: $triangle-border-widths;
            border-right-width: 0;
            border-bottom-width: $triangle-border-widths;
            border-left-width: $triangle-border-widths;
            border-color: transparent transparent transparent #ffffff;
            margin-left: $triangle-margins;
         }
      }
   }

   &.disable {
      .triangle {
         width: 0;
         height: 0;
         border-style: solid;

         &.triangle-1 {
            border-top-width: $triangle-border-widths;
            border-right-width: 0;
            border-bottom-width: $triangle-border-widths;
            border-left-width: $triangle-border-widths;
            border-color: transparent transparent transparent #ffffff;
         }

         &.triangle-2 {
            border-top-width: $triangle-border-widths;
            border-right-width: $triangle-border-widths;
            border-bottom-width: $triangle-border-widths;
            border-left-width: 0;
            border-color: transparent #ffffff transparent transparent;
         }
      }
   }

   &:hover {
      background-color: #2ecc71;
      border: 2px solid $border-color;

      &.enable {
         .triangle {
            &.triangle-1 {
               border-color: transparent #036b04 transparent transparent;
            }

            &.triangle-2 {
               border-color: transparent transparent transparent #036b04;
            }
         }
      }

      &.disable {
         .triangle {
            &.triangle-1 {
               border-color: transparent transparent transparent #036b04;
            }

            &.triangle-2 {
               border-color: transparent #036b04 transparent transparent;
            }
         }
      }
   }
}