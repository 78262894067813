.connectSlack{
  height: calc(61vh - 55px - 2%);
  padding: 2% 0;
}

.connectSlack.slackMessenger{
  height: calc(61vh - 2% - 44px);
  padding-bottom: unset;
}

.connectSlack img{
  object-fit: contain;
  height: 100%;
  width: 70%;
}

.connectSlack .mdc-button:not(:disabled).primePillBtn{
  background-color: #038C7F;
  padding: 3%;
  bottom: 3%;
}

.connectSlack .mdc-button:not(:disabled).primePillBtn.slackConvo{
  min-width: 36px;
}

.connectSlack .mdc-button:not(:disabled).primePillBtn:hover{
  background-color: rgb(3, 140, 127, 0.75);
}

.slackList{
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1rem;
}

.slackListItem{
  display: flex;
  width: 95%;
  padding: 2% 2% 2% ;
  border-radius: 0.5rem;
  align-self: center;
}

.slackListItem:hover{
  background-color: #5c5c5c;
}

.slackListItem img{
  height: 66px;
  width: 66px;
  border-radius: 0.75rem;
}

.slackListItem p{
  margin: 0;
  padding: 0 3%;
  font-size: 17px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
}

.slackListItem .slackMessage{
  width: 100%;
}

.slackMessage{
  gap: 5%;
  position: relative;
}

.slackMessage input{
  padding-right: 15%;
  height: 34px;
}

.slackInviteWrapper{
  display: flex;
  height: 100%;
  width: 100%;
  align-self: flex-start;
  padding: 0 3%;
  gap: 15px;
}

.slackInviteWrapper .primePillBtn{
  border-radius: 0.5rem !important;
  box-shadow: unset !important;
  white-space: nowrap;
}

.messagingNav .mdc-tab-scroller__scroll-area--scroll{
  overflow: hidden;
  margin-bottom: unset !important;
}

.primePillBtn.slackConvo.mdc-button .mdc-button__icon{
 margin: 0;
}

.sMessengerHeader .primePillBtn.exit{
  box-shadow: unset !important;
  border-radius: 0 !important;
  background-color: #343434 !important;
}

.sMessengerHeader .primePillBtn.exit:hover{
  background-color: #080808 !important;
}

.mdc-ripple-upgraded.primePillBtn.exit.mdc-button{
  align-self: center;
  height: 50px;
  padding: 10px;
  border-radius: 0 0.75rem 0.75rem 0 !important;
  bottom: 0;
}
.sMessengerHeader .primePillBtn.exit.mdc-button .mdc-button__icon{
  margin: 0;
  font-size: 36px;
  width: 36px;
  height: 36px;
}

.sMessengerHeader h1{
  color: white;
  font-size: 25px;
  margin: 0;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sMessengerHeader img{
  height: 50px;
  width: 50px;
  border-radius: 1.5rem;
  padding: 10px;
}

.sMessengerDivider{
  width: 90% !important;
  align-self: center !important;
  height: 3px !important;
}

.sMessengerContent{
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.sMessengerInput{
  position: relative;
  width: 25vw;
}

.sMessengerInput input{
  width: 100%;
  outline: unset;
  height: 55px;
  margin: 2px 0 0px 2px;
  padding: 0 80px 0 2%;
  border-radius: 0.25rem;
  align-self: center;
}

.sMessengerInput input:focus{
  outline: unset;
}

.sMessengerInput .mdc-ripple-upgraded.primePillBtn.mdc-button{
  border-radius: 0.25rem;
  height: 55px;
  padding: 2px;
  margin: 2px 2px 0px 0;
  box-shadow: unset;
  position: absolute;
  right: 0;
  bottom: 0;
}

.sMessengerInput .primePillBtn.mdc-button .mdc-button__icon{
  margin: 0;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.sMessengerInput .primePillBtn.mdc-button:disabled{
  background-color: #242424;
}

.sMessengerInput .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-button__icon{
  margin: 0;
  color: white;
}

.slackListItem .slack-badge.rmwc-badge--align-end{
  top: unset !important;
  bottom: 0.25rem;
}

.sMessengerHeader .slack-badge.rmwc-badge--align-end{
  top: unset !important;
  bottom: 0.5rem;
}

.conversationMessage img{
  height: 50px;
  width: 50px;
  border-radius: 1.5rem;
  padding: 10px 10px 10px 10px;
}

.conversationMessage p{
  margin: 0;
}

.conversationMessage .messageName, .conversationMessage .messageText{
  color: white;
}

.messageContent{
  width: 100%;
  align-self: center;
  padding: 10px 10px 10px 0px;
}

.messageContent .flex-container-row{
  gap: 10px;
}

@keyframes fadeInAnno {
  0% {opacity: 0.2}
  100% {opacity: 1}
}

@keyframes fadeOutAnno {
  0% {opacity: 1}
  100% {opacity: 0.2}
}

.canvas-draw, .broadcast-canvas-draw {
  position: absolute;  
  cursor: none;
}
.canvas-draw {
  z-index: 2;
}

.broadcast-canvas-draw {
  z-index: 0;
}

#annotation-canvas {
  position: absolute;
  width: calc(100% - 1rem);
  height: calc(100% - 3rem);
  top: 2.5rem;
  border: 1px solid #eeeeee;
  box-shadow: 0px 0px 6px #eeeeee;
  border-radius: 0.5rem;
  /* background-color: #ffffff44; */
  /* z-index: 1; */
}

#annotation-panel:hover {
  opacity: 1;
  animation: fadeInAnno 0.25s;
}

#annotation-panel {
  animation: fadeOutAnno 0.25s;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000000bb;
  opacity: 0.2;
  height: 50px;
  z-index: 5;
  border-radius: 0.5rem;
}

.annotation-control {
  display: inline-block;
  position: relative;
  width: 50px;
  top: 0.45rem;
  left: 0.25rem;
}

.annotation-textbox-user-container, .annotation-text-broadcast {
  position: absolute !important;
}

.annotation-textbox-user {
  background-color: #ffffff88 !important;
  border: 1px dotted #000000;
}

.annotation-textbox-broadcast {
  background-color: #aaaaff88 !important;
  border: 1px solid #888888
}

.annotation-textbox-user-container {
  position: absolute;
}

.delete-textbox-user {
  height: 24px;
  width: 24px;
  background-color: red;
}

.excalidraw {
  border-radius: 5px;
}

/*
DONE TO SPECIFICALLY TARGET EXCALIDRAW ELEMENTS AS API
DOES NOT SUPPORT HIDING TOOLBAR ITEMS YET
*/
.layer-ui__wrapper__top-right.zen-mode-transition,
.mobile-misc-tools-container,
.Island .Stack .Shape:nth-child(12),
.ToolIcon.ToolIcon__lock,
.App-toolbar__divider,
.ToolIcon.Shape[title="Insert image — 9"] {
  display: none !important;
}