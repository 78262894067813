.spaceInfo.mdc-dialog.mdc-dialog{
  width: 560px;
  height: 100%;
  position: absolute;
  left: calc(36% - 270px);
  top: calc(51% - 270px);
}

.messaging.mdc-dialog.mdc-dialog{
  height: 61vh;
  width: 23vw;
  position: absolute;
  top: unset;
  bottom: 7rem;
  left: 30%;
}

.uploadAsset2.mdc-dialog.mdc-dialog{
  width: 72%;
  height: 100%;
  position: absolute;
  left: auto !important;
  right:0;
  top: 47px;
  z-index:unset !important;
  margin: 0 2% 0 1%;
	max-height: calc(-10rem + 100vh) !important;
}


.uploadAsset2.mdc-dialog--open .mdc-dialog__scrim, .messaging.mdc-dialog--open .mdc-dialog__scrim, .spaceInfo.mdc-dialog--open .mdc-dialog__scrim{
  opacity: 0 !important;
  background: unset;
}

.uploadAsset2.mdc-dialog .mdc-dialog__surface, .messaging.mdc-dialog .mdc-dialog__surface{
  height: 100%;
  width: 100%;
  max-height: unset;
  max-width: unset;
  border-radius: 0.5rem;
}

.uploadAsset2.mdc-dialog.mdc-dialog .mdc-dialog__container{
  height:100% !important;
  width:100% !important;
}


.messaging.mdc-dialog .mdc-dialog__surface{
  min-width: unset
}

.messaging.mdc-dialog .mdc-dialog__surface{
  padding: unset !important
}

.messagingWrapper .mdc-tab .mdc-tab__text-label{
  color: white;
}

.messagingWrapper .mdc-tab--active .mdc-tab__text-label{
  color: #038C7F;
}

.messagingWrapper .mdc-tab-indicator .mdc-tab-indicator__content--underline{
  border-color: #038C7F;
}

.messagingWrapper .mdc-tab__ripple::before, .messagingWrapper .mdc-tab__ripple::after{
  background-color: #038C7F;
}

.messagingWrapper .mdc-tab-indicator--active{
  background-color: RGBA(3, 140, 127, 0.1);
}

.messagingNav{
  height: 48px;
}

.messagingButtonWrapper{
  position: relative;
}

.rcw-conversation-container{
    opacity: 1 !important;
}

.rcw-launcher{
  display: none;
}

.rcw-widget-container{
  margin: 0 !important;
  max-width: unset !important;
  width: 100% !important;
  position: absolute !important;
}

.messagingWrapper{
  position: relative;
  height: 100%;
  width: 25vw;
  display: flex;
  flex-direction: column;
}

.messagingWrapper .rcw-conversation-container.active{
  width: 100%;
}

.messagingWrapper .rcw-conversation-container .rcw-header{
  display: none;
}


.badge {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #b00020;
  position: absolute;
  right: 0.5rem;
  top: 0;
}

.sMessengerHeader .rmwc-badge-anchor{
  display: inherit !important;
}
