/**
 * @@ Assumes that parent element has height of 30px!
**/

$btn-size: 24px;

.tile-btns {
   display: flex;
   width: $btn-size;
   height: $btn-size;
   border-radius: 50%;
   position: relative;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   background-color: transparent;
   border: 2px solid #ffffff;
   cursor: default;
   opacity: 0.7;
   margin-left: 2px;
   margin-right: 2px;

   &:hover {
      opacity: 1;
   }
}