@import "./_configs/index.scss";

$border-color: #e2a137;
$line-color: #9a570d;
$background-color: #ffbb40;


.unmount-btn {
   @extend .tile-btns;

   .line {
      width: 70%;
      height: 2px;
      background-color: #ffffff;
   }

   &:hover {
      background-color: $background-color;
      border: 2px solid $border-color;

      .line {
         background-color: $line-color;
      }
   }
}