@import "./_configs/index.scss";

$line-color: #7d0808;
$background-color: #fd5854;
$border-color: #d84843;



.remove-btn {
   @extend .tile-btns;

   .line {
      position: absolute;
      width: 70%;
      height: 2px;
      background-color: #ffffff;
   }

   .line-1 {
      transform: rotate(45deg);
   }

   .line-2 {
      transform: rotate(-45deg);
   }

   &:hover {
      background-color: $background-color;
      border: 2px solid $border-color;

      .line {
         background-color: $line-color;
      }
   }
}