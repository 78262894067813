.sign-in{
  font-size: 15px;
  padding-top: 2%;
  text-align: center;
}

.sign-in p{
  margin: 0;
}

.sign-in a{
  color: #038C7F;
  text-decoration: underline;
}

.homePage{
  gap: 16px;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-input{
  width: 100%;
  
}

.login-field {
  width: 100%;
  height: 5px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #C7CFD9;
  border: none;
  border-bottom: .5px solid #ccc;
  outline: none;
  background-color: transparent;
  padding: 18px 10px;
  z-index: 9999;
}

._spectrum-Textfield--quiet_61339 ._spectrum-Textfield-input_61339._is-focused_61339, ._spectrum-Textfield--quiet_61339 ._spectrum-Textfield-input_61339:focus,
[dir=ltr] ._spectrum-Search_973c6._is-quiet_973c6 ._spectrum-Search-input_973c6
{
    color:#fff;
}

._spectrum-Textfield-input_61339._focus-ring_61339:not(:active){
  border-color: #038C7F;
  box-shadow: 0 0 0 1px #038C7F;
}

._spectrum-Textfield-input_61339 {
  background-color: transparent;
  border-width: 0;
  color: white;
  border-color: transparent;
}
._spectrum-Textfield-input_61339:hover {
  border: transparent;
}
._spectrum-Textfield-input_61339:target {
  background-color: transparent;
  border: transparent;
  color: white;
}
._spectrum-Textfield-input_61339:active {
  background-color: transparent;
  color: white;
  border: transparent;
}

._spectrum-Button--primary_7a745._focus-ring_7a745, ._spectrum-Button--primary_7a745._is-hovered_7a745{
  background-color: #3a3c3f;
  border-color: #3a3c3f;
  color: #fcf5f5;
}

._spectrum-Button_7a745._focus-ring_7a745::after{
  box-shadow: 0 0 0 2px #038C7F;
}

.login-input{
  margin-top: 60px;
  width: 400px;
  z-index: 9999;
}

.login-input .spectrum[class*="spectrum-"] {
   width: 100%;
 }
 
.login-btn {
    color: white;
    border-color: white;
    width: 150px;
    border-width: .5px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}
