.folderTile .data-tile-shield{
  bottom: 20px;
  height: 21vh;
}

.data-tile-shield{
  height: 23vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after{
  background-color: #747474;
}