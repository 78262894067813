.spaceInfoButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.assetButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.messagingButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.annotationButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.participantsButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.calendarButtonWrapper.closeButton .ndrcButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon,
.videoButtonWrapper.closeButton .rmwc-icon.rmwc-icon--ligature.material-icons.mdc-fab__icon {
   /* color: #b00020 !important; */
}

.sidebar-toggle .material-icons {
   font-size: 28px;
}